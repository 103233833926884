import * as React from 'react';
import { useState, Fragment, /*useLayoutEffect*/ } from 'react';
import { Link } from "react-router-dom"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import Check from '@mui/icons-material/Check';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider/Divider';
import { useNavigate, /*Redirect*/ } from "react-router-dom"
import { NestedMenuItem } from "mui-nested-menu";

import { withTranslation, useTranslation } from 'react-i18next'

import LogoCabecera from '../assets/images/logos/ekolurra.png'
import ThemeGeroa from '../ThemeGeroa'
import { useStore } from 'react-create-use-store';
import store from './../adderStore.js'


const Cabecera = ({ t, ...props }) => {

  const { state, actions } = useStore(store)
  const { i18n } = useTranslation()
  const navigate = useNavigate();
  let miMenuGeroa = []
  miMenuGeroa[0] = { texto: t("MenuDrawer.PRODUCTOS"), habilitado: state.esProductor, salto: "/MisProductos" }
  miMenuGeroa[1] = { texto: t("MenuDrawer.PEDIDOS"), habilitado: true, salto: "/MisPedidos" }
  miMenuGeroa[2] = { texto: t("MenuDrawer.INFORMES"), habilitado: true, salto: "/MisInformes" }
  miMenuGeroa[3] = { texto: t("MenuDrawer.FACTURAS"), habilitado: true, salto: "/MisFacturas" }
  miMenuGeroa[4] = { texto: t("MenuDrawer.CALENDARIO"), habilitado: true, salto: "/FechasEntrega" }
  miMenuGeroa[5] = { texto: t("MenuDrawer.MAPA"), habilitado: true, salto: "/MisMapas" }
  miMenuGeroa[6] = { texto: t("MenuDrawer.CLIENTES"), habilitado: true, salto: "/Clientes" }
  if (usuarioEspecial()) {
    miMenuGeroa[7] = { texto: "-PR/CO-", habilitado: true, salto: "/CambioPrCo" }
  }
  //
  let miMenuIdiomas = []
  miMenuIdiomas[0] = { texto: t("MenuDrawer1.Español"), abrev: "Es" }
  miMenuIdiomas[1] = { texto: t("MenuDrawer1.Euskera"), abrev: "Eus" }
  //
  let miMenuUsuario = []
  miMenuUsuario[0] = t("MenuIdentificarse.Identificarse")
  miMenuUsuario[1] = t("MenuIdentificarse.Desconectarse")
  //
  let miMenuInformes = []
  miMenuInformes[0] = { texto: t("MisInformes.Productos"), habilitado: state.esProductor, salto: "/InfProductos" }
  miMenuInformes[1] = { texto: t("MisInformes.Pedidos"), habilitado: true, salto: "/InfPedidos" }
  miMenuInformes[2] = { texto: t("MisInformes.HojasDeEntregas"), habilitado: state.esProductor, salto: "/InfEntregas" }
  miMenuInformes[3] = { texto: t("MisInformes.ProductosYMercados"), habilitado: true, salto: "/InfProductosYMercados" }
  miMenuInformes[4] = { texto: t("MisInformes.VentasClientes"), habilitado: true, salto: "/InfVentasClientes" }
  miMenuInformes[5] = { texto: t("MisInformes.Clientes"), habilitado: true, salto: "/InfClientes" }
  miMenuInformes[6] = { texto: t("MisInformes.ProductosProductores"), habilitado: state.esProductor, salto: "/InfProductosProductores" }

  let miMenuFacturas = []
  miMenuFacturas[0] = { texto: t("MisFacturas.Resumidas"), habilitado: true, salto: "/FacResumidas" }
  miMenuFacturas[1] = { texto: t("MisFacturas.Desglosadas"), habilitado: true, salto: "/FacDesglosadas" }



  // Ganchos de los 3 menus
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElIdioma, setAnchorElIdioma] = useState(null);
  const [anchorElInformes, setAnchorElInformes] = useState(null);
  const [anchorElFacturas, setAnchorElFacturas] = useState(null);

  // Aperturas de Menus
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  }
  const handleOpenInformesMenu = (event) => {
    setAnchorElInformes(event.currentTarget);
  }
  const handleOpenFacturasMenu = (event) => {
    setAnchorElFacturas(event.currentTarget);
  }
  const handleOpenIdiomaMenu = (event) => {
    setAnchorElIdioma(event.currentTarget);
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  }

  // Cierres de los 3 menus
  const handleCloseNavMenu = (event, index) => {
    setAnchorElNav(null)
    if (index !== "backdropClick") {
      let salto
      // Preparar el salto
      if (state.emailUsuario === '') {
        // Salto futuro
        salto = '/Identificarse'
        actions.cambiarSaltarA(miMenuGeroa[index].salto)
      } else {
        salto = miMenuGeroa[index].salto
      }
      return navigate(salto)
    }
  }


  const handleCloseInformesMenu = (event, index) => {
    setAnchorElInformes(null)
    // Hacer solo si index !== "backdropClick"
    if (index !== "backdropClick") {
      let salto
      // Preparar el salto
      if (state.emailUsuario === '') {
        // Salto futuro
        salto = '/Identificarse'
        actions.cambiarSaltarA(miMenuInformes[index].salto)
      }
      else {
        if (index < miMenuInformes.length) {
          salto = miMenuInformes[index].salto
        }
      }
      if (salto !== null) return navigate(salto)
    }
  }


  const handleCloseFacturasMenu = (event, index) => {
    setAnchorElFacturas(null)
    // Hacer solo si index !== "backdropClick"
    if (index !== "backdropClick") {
      let salto
      // Preparar el salto
      if (state.emailUsuario === '') {
        // Salto futuro
        salto = '/Identificarse'
        actions.cambiarSaltarA(miMenuFacturas[index].salto)
      }
      else {
        if (index < miMenuFacturas.length) {
          salto = miMenuFacturas[index].salto
        }
      }
      if (salto !== null) return navigate(salto)
    }
  }


  const handleCloseIdiomaMenu = (event, index) => {
    setAnchorElIdioma(null);
    // Hacer solo si index !== "backdropClick"
    if (index !== "backdropClick") {
      let idioma = miMenuIdiomas[index].abrev
      //
      i18n.changeLanguage(idioma)
      //
      localStorage.setItem('idioma', idioma)
      //
      actions.cambiarIdioma(idioma)
      //
    }

  }

  const handleCloseUserMenu = (event, index) => {
    setAnchorElUser(null);
    // Si le damos a Desconectar
    if (index === 1) {
      actions.cambiarEmailUsuario("")
      //
      return navigate('/')
    }
  }




  // Permitir varias opciones solo a:
  //     Sareko ()
  //     Geroa (armentia / soporte)
  function usuarioEspecial() {
    let ret = false
    let u = state.emailUsuario
    if (u === "armentia@geroa.com" || u === "soporte@geroa.com" || u === 'sustatu@ekolurra.eus') {
      ret = true
    }
    return ret
  }

  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const [value, setValue] = React.useState("menu-item-1");



  const setSelectValue = (subopcion, value) => {
    /*
    setAnchorEl(null)
    setValue(value);
    handleClose();
    */
    setAnchorElNav(null)

    //console.log('subopcion = ', subopcion, ' --- value = ', value)
    let sal = subopcion === "Informes" ? miMenuInformes[value].salto : miMenuFacturas[value].salto
    sal = state.emailUsuario === '' ? '/Identificarse' : sal
    actions.cambiarSaltarA(sal)
    //console.log('salto = ', sal, ' -x x-', state.emailUsuario)
    return navigate(sal)
  }


  return (
    <AppBar position="static">
      <Container maxWidth="false" sx={{ height: ThemeGeroa.heightCabecera }}>
        <Toolbar disableGutters>

          {/* Logo */}
          <img src={LogoCabecera} alt={'Sareko'} title={'Sareko'} style={{ width: 'auto', height: 40 }} />

          {/* Texto de la Cabecera Izquierda (Derecha del Logo)*/}
          <Typography
            variant="nombreEmpresaCabecera"
            component="a"
            href="/"
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: 'none', sm: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Eneek
          </Typography>

          {/* Menú Hamburguesa */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon key={"Icono_1"} />
            </IconButton>
            <Menu
              id="menu-ham"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {miMenuGeroa.map((opcion, index) => (
                index !== 2 && index !== 3 ?
                  <MenuItem
                    key={"ham0_" + index}
                    onClick={(event) => handleCloseNavMenu(event, index)}
                  >
                    <Typography variant="menuIzquierdacabecera">{opcion.texto}</Typography>
                  </MenuItem>
                  :
                  <NestedMenuItem
                    key={"ham1_" + index}
                    label={opcion.texto}
                    //parentMenuOpen={true}
                    parentMenuOpen={Boolean(anchorElNav)}
                    >
                    {index === 2 ?
                      miMenuInformes.map((infor, i) => (
                        <MenuItem
                          key={"ham2_" + i}
                          data-value={i}
                          onClick={(e) => { setSelectValue("Informes", e.currentTarget.dataset.value) }}
                        >
                          {infor.texto}
                        </MenuItem>
                      ))
                      :
                      miMenuFacturas.map((infor, j) => (
                        <MenuItem
                          key={"ham3_" + j}
                          data-value={j}
                          onClick={(e) => { setSelectValue("Facturas", e.currentTarget.dataset.value) }}
                        >
                          {infor.texto}
                        </MenuItem>
                      ))
                    }
                  </NestedMenuItem>
              ))}
            </Menu>
          </Box>

          {/* Menú Central de Opciones de Texto*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
            {miMenuGeroa.map((opcion, index) => (

              index !== 2 && index !== 3 ?
                <Button
                  key={"tex" + index}
                  disabled={!opcion.habilitado}
                  onClick={(event) => handleCloseNavMenu(event, index)}
                  sx={{ my: 2 }}
                >
                  <Typography variant="menuCentralCabecera">{opcion.texto}</Typography>
                </Button>
                :
                <Fragment key={"fra" + index}>
                  <Tooltip title={t("MisInformes.AbreInformes")}>
                    <Button
                      disabled={!opcion.habilitado}
                      onClick={index === 2 ? handleOpenInformesMenu : handleOpenFacturasMenu}
                      sx={{ my: 2, p: 0, mr: 1 }}
                    >
                      <Typography variant="menuCentralCabecera">{miMenuGeroa[index].texto}</Typography>
                    </Button>
                  </Tooltip>

                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-bar"
                    anchorEl={index === 2 ? anchorElInformes : anchorElFacturas}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(index === 2 ? anchorElInformes : anchorElFacturas)}
                    onClose={index === 2 ? handleCloseInformesMenu : handleCloseFacturasMenu}
                  >
                    {index === 2 && miMenuInformes.map((opcionInforme, index) => (
                      <MenuItem
                        disabled={!opcionInforme.habilitado}
                        key={"inf" + index}
                        onClick={(event) => handleCloseInformesMenu(event, index)}
                      >
                        <Typography variant="menuIdiomaCabecera">{opcionInforme.texto} </Typography>
                      </MenuItem>
                    ))}
                    {index === 3 && miMenuFacturas.map((opcionFacturas, index) => (
                      <MenuItem
                        disabled={!opcionFacturas.habilitado}
                        key={"fac" + index}
                        onClick={(event) => handleCloseFacturasMenu(event, index)}
                      >
                        <Typography variant="menuIdiomaCabecera">{opcionFacturas.texto} </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Fragment>
            )
            )}
          </Box>

          {/* Menú del Idioma*/}
          <Box sx={{ flexGrow: 0, mr: 1 }}>
            <Tooltip title="Abre Idiomas">
              <IconButton onClick={handleOpenIdiomaMenu} color="inherit" sx={{ p: 0 }}>
                <LanguageIcon />
                <Typography
                  variant="menuIdiomaCabecera"
                  //component="a"
                  //href="/"
                  sx={{
                    mr: 2,
                    ml: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {state.idioma}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-idi"
              anchorEl={anchorElIdioma}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElIdioma)}
              onClose={handleCloseIdiomaMenu}
            >
              {miMenuIdiomas.map((opcionIdioma, index) => (
                <MenuItem
                  key={"idi" + index}
                  onClick={(event) => handleCloseIdiomaMenu(event, index)}
                >
                  {state.idioma === opcionIdioma.abrev ? <Check sx={{ mr: 1 }} /> : <Typography sx={{ ml: 4 }} />}
                  <Typography variant="menuIdiomaCabecera">{opcionIdioma.texto} </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>


          {/* Menú de Usuario*/}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abre Usuario">
              <IconButton onClick={handleOpenUserMenu} color="inherit" sx={{ p: 0 }}>
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {miMenuUsuario.map((opcionUsuario, index) => (
                <Box key={"usu" + index}>
                  {state.emailUsuario !== "" && index === 0 ?
                    <Fragment>
                      <Typography sx={{ ml: 2, mr: 2, mb: 10 }} variant="nombreUsuarioCabecera" noWrap>
                        {state.emailUsuario}
                      </Typography>
                      <Divider sx={{ ml: 0, mr: 0, tp: 10 }} />
                    </Fragment>
                    :
                    <Fragment />
                  }
                  {index === 0 ?
                    <MenuItem
                      key={"us_" + index}
                      component={Link}
                      to={"/Identificarse"}
                      onClick={(event) => handleCloseUserMenu(event, index)}
                      disabled={state.emailUsuario !== ""}
                    >
                      <Typography variant="menuUsuarioCabecera">{opcionUsuario}</Typography>
                    </MenuItem>
                    :
                    <MenuItem
                      key={"us_" + index}
                      onClick={(event) => handleCloseUserMenu(event, index)}
                      disabled={state.emailUsuario === ""}
                    >
                      <Typography variant="menuUsuarioCabecera">{opcionUsuario}</Typography>
                    </MenuItem>
                  }
                </Box>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default withTranslation()(Cabecera)