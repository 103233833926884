import { createStore } from 'react-create-use-store';

// initial state
const state = {
  count: 5,
  idioma: 'Es',
  emailUsuario: '',
  esProductor: true,
  codProveedor: '',
  saltarA: '',
  mensaje: '',
  gruposIva: [],
  familias: [],
  familiasSubfamilias: [],
  uniAVer: [],
  uni2AVer: [],
  mercadosProveedor: [],
  tiposDePuntos: [],
};

// list of action functions
const actions = {
  cambiarIdioma(id) {
    store.setState(old => ({ ...old, idioma: id }));
  },
  cambiarEmailUsuario(us) {
    store.setState(old => ({ ...old, emailUsuario: us }));
  },
  cambiarEsProductor(ep) {
    store.setState(old => ({ ...old, esProductor: ep }));
  },
  cambiarCodProveedor(cp) {
    store.setState(old => ({ ...old, codProveedor: cp }));
  },
  cambiarSaltarA(sa) {
    store.setState(old => ({ ...old, saltarA: sa }));
  },
  cambiarMensaje(me) {
    store.setState(old => ({ ...old, mensaje: me }));
  },
  cambiarGruposIva(gi) {
    store.setState(old => ({ ...old, gruposIva: gi }));
  },
  cambiarFamilias(fa) {
    store.setState(old => ({ ...old, familias: fa }));
  },
  cambiarFamiliasSubfamilias(faSu) {
    store.setState(old => ({ ...old, familiasSubfamilias: faSu }));
  },
  cambiarUniAVer(u1) {
    store.setState(old => ({ ...old, uniAVer: u1 }));
  },
  cambiarUni2AVer(u2) {
    store.setState(old => ({ ...old, uni2AVer: u2 }));
  },
  cambiarMercadosProveedor(mer) {
    store.setState(old => ({ ...old, mercadosProveedor: mer }));
  },
  cambiarTiposDePuntos(pun) {
    store.setState(old => ({ ...old, tiposDePuntos: pun }));
  }

};

// create and export the store
const store = createStore({ state, actions });
export default store;