import React, { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import AmpliaImagen from "../../components/AmpliaImagen.js";

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

//import { alpha, styled } from '@mui/material/styles';
//import InputBase from '@mui/material/InputBase';



import ThemeGeroa from './../../ThemeGeroa.js'
import { withTranslation } from 'react-i18next'

import { PrecioConIva } from '../../config/funcionesPropias.js'


import { useStore } from 'react-create-use-store'
import store from './../../adderStore.js'

import config from './../../config/config.js'
import DialogProducto from './DialogProducto.js'
//import { PanoramaSharp } from '@mui/icons-material';



const PanelFichaProducto = ({ t, ...props }) => {

    const { state, /*actions*/ } = useStore(store);


    //
    const [producto, setProducto] = useState(props.producto)
    const [foto, setFoto] = useState(config.rutaFotosProductos + props.producto.ProCod + '.jpg?f=' + (new Date().getTime()))
    //
    //const indexFam = state.familias.findIndex(fam => fam.FamCod === producto.ProFam);
    //const nombreFam = state.idioma === "Es" ? state.familias[indexFam]?.FamDesC : state.familias[indexFam]?.FamDesE
    // Modificar por Familia + SubFamilia. //
    const indexFam = state.familiasSubfamilias.findIndex(sub => sub.FamCod + sub.SubCod === producto.ProFam + producto.ProSub);
    const nombreFam = ((index) => {
        let nombre = ""
        if (index !== -1) {
            let f, s
            if (state.idioma === "Es") {
                f = state.familiasSubfamilias[index]?.FamDesC
                s = state.familiasSubfamilias[index]?.SubDesC
            } else {
                f = state.familiasSubfamilias[index]?.FamDesC
                s = state.familiasSubfamilias[index]?.SubDesC
            }
            nombre = f + (s === "" ? "" : " - " + s)
        }
        return nombre
    })(indexFam)


    const [openEditar, setOpenEditar] = useState(false)
    const handleEditar = event => {
        setOpenEditar(!openEditar)
    }

    let id = producto.ProCod
    let arrP = props.arrProductos


    function actualizador(p, g, r) {
        if (g) {
            setProducto({ ...p })
            setFoto(config.rutaFotosProductos + props.producto.ProCod + '.jpg?f=' + (new Date().getTime()))
        }
        // Recargar todos los productos. //
        if (r) {
            props.recargar()
        }
        //
        setOpenEditar(!openEditar)
    }



    return (
        <ThemeProvider theme={ThemeGeroa}>
            {openEditar &&
                <DialogProducto
                    producto={producto}
                    tipo={"E"}
                    actualizador={actualizador}
                    arrP={arrP}
                />}
            <Typography sx={{ ml: 0 }} variant="verde15">
                {nombreFam}
            </Typography>
            <Accordion
                key={"Ap" + id}
                expanded={true}
            >
                <AccordionSummary
                    id={"AS" + id}
                    //&expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                //onClick={handleExpanded(producto)}
                >
                    <AmpliaImagen
                        prod={producto.ProCod}
                        foto={foto}
                        familia={nombreFam}
                    />
                    <Container>
                        <Typography variant="azul15">
                            {state.idioma === "Es" ? producto.ProDesC : producto.ProDesE}
                            {" (" + producto.ProCod + ") "}
                        </Typography>
                        <br />
                        <Grid container justifyContent="space-between">
                            <Grid>
                                <Typography variant="azul13">
                                    {Number(producto.ProEIn) > 0 ? "  " + producto.ProExi + " " + arrP.uniAVer[producto.ProUni] : ""}
                                </Typography>
                                <Typography sx={{ ml: 2 }} variant="gris13" display="inline">
                                    {producto.ProLot !== "" ? (t("MisProductos.Lote") + ':') : ""}
                                </Typography>
                                <Typography variant="azul13">
                                    {"  " + producto.ProLot}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    //startIcon={<SendIcon />}
                                    sx={{ mr: 2, width: 90, height: 30 }}
                                    onClick={handleEditar}
                                >
                                    {t("General.Editar")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid sx={{ mt: -1 }}>
                            {producto.Mercados.map((m, k) => (
                                /* Ver tarifas siempre, tanto si están marcadas como visibles como si no. //
                                /*m.TarVis === 1 && m.TarVal > 0 && */
                                m.TarVis === 1 && <Fragment key={'m' + id + m.MerCod + state.idioma}>
                                    <Typography variant="azul13">
                                        <Typography sx={{ ml: 0 }} variant="gris13">{m.MerDesC}</Typography>
                                        <Typography sx={{ ml: 1 }} variant="azul13">
                                            {PrecioConIva(m.TarVal, state.gruposIva, producto.ProIva)}
                                            {producto.ProEIn !== '' ? " € / " + arrP.uniAVer[producto.ProUni] + " " : ''}
                                            {producto.ProFac !== "" && producto.ProFac !== 0 && producto.ProFac !== 1 && m.TarVal > 0 ?
                                                (state.idioma === "Es" ?
                                                    t("MisProductos.ElProductoLeSaleA") + " " +
                                                    PrecioConIva(m.TarVal / producto.ProFac, state.gruposIva, producto.ProIva) + " € / " +
                                                    (producto.ProUn2 !== '' ? arrP.un2AVer[producto.ProUn2] : '')
                                                    :
                                                    PrecioConIva(m.TarVal / producto.ProFac, state.gruposIva, producto.ProIva) + " € / " +
                                                    (producto.ProUn2 !== '' ? arrP.un2AVer[producto.ProUn2] : '') +
                                                    t("MisProductos.ElProductoLeSaleA")
                                                )
                                                :
                                                ''
                                            }
                                        </Typography>
                                        <br />
                                    </Typography>
                                </Fragment>
                            ))}
                        </Grid>
                    </Container>
                </AccordionSummary>
            </Accordion>
        </ThemeProvider>
    )

}


/*

 {state.idioma === "Es" ? 
    t("MisProductos.ElProductoLeSaleA") + 
    :
    yyyyyyyy

 }

*/


export default withTranslation()(PanelFichaProducto)