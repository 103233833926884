import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';


import { ThemeProvider } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { listaFacResProvCoor, descargaPdf } from './../../apis/api-factura.js'

import BotonFecha from "../../components/BotonFecha.js";
import BotonPdf from "../../components/BotonPdf.js";
import BotonXlsx from "../../components/BotonXlsx.js";
import MensajeCabecera from "../../components/MensajeCabecera.js";
import Lista from "../../components/Lista.js";
import config from './../../config/config.js'
import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio } from "../../config/funcionesPropias.js";

// Generar ZIP con facturas. //
import JSZip from 'jszip';
import saveAs from 'save-as';

const FacResumidas = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const ImgPdf = require('./../../assets/images/logos/descarga.png')


    const { state, actions } = useStore(store);

    let date = new Date()
    const [estado, setEstado] = useState(
        {
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: undefined, // 1
            seleccionMercados: [], // listaMercados,
            lineasFacturas: [],
            lineasExcel: []
        }
    )




    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        if (state.mercadosProveedor.length > 0) {

            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = []
            for (let i = 0; i < state.mercadosProveedor.length; i++) {
                if (i === 0) {
                    listaMercados.push(t("MisInformes.Todos"))
                }
                listaMercados.push(
                    state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
                )
            }
            setEstado(estado => ({ ...estado, seleccionMercados: listaMercados, mercado: 1 }))
        }
    }, [state.mercadosProveedor, t, state.idioma])


    // Recargar cuando cambia el mercado en el filtro. //
    useEffect(() => {
        if (estado.mercado !== undefined) {
            loadLineasFacturasIniciales()
        }
    }, [estado.mercado, estado.fechaInicio, estado.fechaFin])


    // Descargar ZIP con las Facturas. //
    const downloadFiles = () => {
        const zip = new JSZip()
        let count = 0
        estado.lineasFacturas.forEach(async f => {
            let fichero = f.Numero +'.pdf'
            try {
                const blob = await descargaPdf({ factura: f.Numero })
                zip.file(fichero, blob, { binary: true })
                count++
                if (count === estado.lineasFacturas.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, "facturas.zip")
                    })
                }
            } catch (err) {
                console.log(err)
            }
        })
    }




    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = (event) => {
        setEstado(estado => ({ ...estado, mercado: event.target.value }))
    }

    /// Desde Fecha ///
    const actualizadorFechaDesde = (fecha) => {
        setEstado(estado => ({ ...estado, fechaInicio: fecha }))
    }

    /// Hasta Fecha ///
    const actualizadorFechaHasta = (fecha) => {
        setEstado(estado => ({ ...estado, fechaFin: fecha }))
    }


    // Cargar las Facturas. //
    async function loadLineasFacturasIniciales() {
        if (estado.lineasFacturas.length > 0) {
            setEstado({ ...estado, lineasFacturas: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        if (state.mercadosProveedor.length > 0) {
            let params = {
                proveedor: state.codProveedor,
                esProductor: state.esProductor ? "1" : "0",
                mercado: estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod,
                diaDesde: FechaAaaaMmDd(estado.fechaInicio),
                diaHasta: FechaAaaaMmDd(estado.fechaFin)
            }
            await listaFacResProvCoor(params).then((data) => {
                console.log('datos = ', data)
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }


    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisFacturas.Numero"),
                t("MisFacturas.Fecha"),
                t("MisFacturas.Mercado"),
                t("MisFacturas.Productor"),
                t("MisFacturas.Cliente"),
                t("MisFacturas.Base1"),
                t("MisFacturas.Iva1"),
                t("MisFacturas.Cuota1"),
                t("MisFacturas.Recargo1"),
                t("MisFacturas.CuotaRec1"),
                t("MisFacturas.Base2"),
                t("MisFacturas.Iva2"),
                t("MisFacturas.Cuota2"),
                t("MisFacturas.Recargo2"),
                t("MisFacturas.CuotaRec2"),
                t("MisFacturas.Base3"),
                t("MisFacturas.Iva3"),
                t("MisFacturas.Cuota3"),
                t("MisFacturas.Recargo3"),
                t("MisFacturas.CuotaRec3"),
                t("MisFacturas.Base4"),
                t("MisFacturas.Iva4"),
                t("MisFacturas.Cuota4"),
                t("MisFacturas.Recargo4"),
                t("MisFacturas.CuotaRec4"),
                t("MisFacturas.Exento"),
                t("MisFacturas.Bases"),
                t("MisFacturas.Cuotas"),
                t("MisFacturas.Total")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                //console.log('datos = ', data)
                resultado.push({
                    Numero: data[i].FacNum,
                    Fecha: FechaDdMmAAAA(new Date(data[i].FacFec)),
                    Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Productor: data[i].UsuRazonSocial,
                    Cliente: data[i].CliNom,
                    Bases: Number(data[i].FacBa1) + Number(data[i].FacBa2) + Number(data[i].FacBa3) + Number(data[i].FacBa4) + Number(data[i].FacExe),
                    Cuotas: Number(data[i].FacCi1) + Number(data[i].FacCi2) + Number(data[i].FacCi3) + Number(data[i].FacCi4) + Number(data[i].FacCr1) + Number(data[i].FacCr2) + Number(data[i].FacCr3) + Number(data[i].FacCr4),
                    Total: Number(data[i].FacTot)
                })
                resultadoExcel.push({
                })
                resultadoExcel[i][cabeceraExcel[0]] = data[i].FacNum
                resultadoExcel[i][cabeceraExcel[1]] = FechaDdMmAAAA(new Date(data[i].FacFec))
                resultadoExcel[i][cabeceraExcel[2]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                resultadoExcel[i][cabeceraExcel[3]] = data[i].UsuRazonSocial
                resultadoExcel[i][cabeceraExcel[4]] = data[i].CliNom
                resultadoExcel[i][cabeceraExcel[5]] = Number(data[i].FacBa1)
                resultadoExcel[i][cabeceraExcel[6]] = Number(data[i].FacIv1) + " %"
                resultadoExcel[i][cabeceraExcel[7]] = Number(data[i].FacCi1)
                resultadoExcel[i][cabeceraExcel[8]] = Number(data[i].FacRe1) + " %"
                resultadoExcel[i][cabeceraExcel[9]] = Number(data[i].FacCr1)
                resultadoExcel[i][cabeceraExcel[10]] = Number(data[i].FacBa2)
                resultadoExcel[i][cabeceraExcel[11]] = Number(data[i].FacIv2) + " %"
                resultadoExcel[i][cabeceraExcel[12]] = Number(data[i].FacCi2)
                resultadoExcel[i][cabeceraExcel[13]] = Number(data[i].FacRe2) + " %"
                resultadoExcel[i][cabeceraExcel[14]] = Number(data[i].FacCr2)
                resultadoExcel[i][cabeceraExcel[15]] = Number(data[i].FacBa3)
                resultadoExcel[i][cabeceraExcel[16]] = Number(data[i].FacIv3) + " %"
                resultadoExcel[i][cabeceraExcel[17]] = Number(data[i].FacCi3)
                resultadoExcel[i][cabeceraExcel[18]] = Number(data[i].FacRe3) + " %"
                resultadoExcel[i][cabeceraExcel[19]] = Number(data[i].FacCr3)
                resultadoExcel[i][cabeceraExcel[20]] = Number(data[i].FacBa4)
                resultadoExcel[i][cabeceraExcel[21]] = Number(data[i].FacIv4) + " %"
                resultadoExcel[i][cabeceraExcel[22]] = Number(data[i].FacCi4)
                resultadoExcel[i][cabeceraExcel[23]] = Number(data[i].FacRe4) + " %"
                resultadoExcel[i][cabeceraExcel[24]] = Number(data[i].FacCr4)
                resultadoExcel[i][cabeceraExcel[25]] = Number(data[i].FacExe)
                resultadoExcel[i][cabeceraExcel[26]] = Number(data[i].FacBa1) + Number(data[i].FacBa2) + Number(data[i].FacBa3) + Number(data[i].FacBa4) + Number(data[i].FacExe)
                resultadoExcel[i][cabeceraExcel[27]] = Number(data[i].FacCi1) + Number(data[i].FacCi2) + Number(data[i].FacCi3) + Number(data[i].FacCi4) + Number(data[i].FacCr1) + Number(data[i].FacCr2) + Number(data[i].FacCr3) + Number(data[i].FacCr4)
                resultadoExcel[i][cabeceraExcel[28]] = Number(data[i].FacTot)
            }
            estado.lineasFacturas = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }

    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisFacturas.Facturas")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 5 / 6, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisFacturas.Resumidas")} />
                </Box>
                <Box sx={{ ml: 3 }}>
                    <Button
                        sx={{ mt: -1, width: 130, height: 30 }}
                        startIcon={<img width={30} height={28} src={ImgPdf} alt="ficherosPdf" />}
                        variant="contained"
                        onClick={downloadFiles}
                    >
                        {"PDFs"}
                    </Button >
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
            </Box>
        </Fragment>
    )



    const listaFacturasResumidasListado = (
        estado.lineasFacturas.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Numero")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Fecha")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Mercado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Cliente")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Bases")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Cuotas")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Total")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{""}</Typography></TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {estado.lineasFacturas.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.Numero}</TableCell>
                                    <TableCell>{l.Fecha}</TableCell>
                                    <TableCell>{l.Mercado}</TableCell>
                                    <TableCell>{l.Cliente}</TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Bases, 2)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Cuotas, 2)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Total, 2)}</Typography></TableCell>
                                    <TableCell> <BotonPdf fichero={config.rutaCarpetaFicherosFacturasProductor + l.Numero + '.pdf'} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaFacturasResumidasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(FacResumidas)