import config from './../config/config.js'
//import React from "react";
////////////////////////////////////////////////////////////////////////import { renderEmail } from 'react-html-email'

const rutaApi = config.rutaApis + '/emails'

const enviaEmailAlbaran = (fichero, mail, subject, mensaje) => {
  return fetch(rutaApi + '/enviaEmailAlbaran/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      fichero: fichero,
      mail: mail,
      subject: subject,
      mensaje: mensaje
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}




const enviaEmailAlbaranTransporte = (cliente, contenidoEmail) => {
  return fetch(rutaApi + '/enviaEmailAlbaran/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      cliente: cliente,
      mensaje: contenidoEmail
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))

}



const enviaEmailCliente = (emailDestino, pedido, tipoEmail, subject, credentials) => {
  return fetch(rutaApi + '/enviaEmail/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      //          'Authorization': 'Bearer ' + credentials.t
    },
    //credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject1: 'subject1',
      mensaje1: 'messageHtml1',
      subject2: 'subject2',
      mensaje2: 'messageHtml2'
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}




const enviaEmailSolicitud = (cliente, asunto, mensaje) => {
  return fetch(rutaApi + '/enviaEmailSolicitud/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      cliente: cliente,
      asunto: asunto,
      mensaje: mensaje
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}




export {
  enviaEmailAlbaran,
  enviaEmailAlbaranTransporte,
  enviaEmailCliente,
  enviaEmailSolicitud
}