import config from './../config/config.js'

const rutaApi = config.rutaApis + '/solicitudes'



///////////////////////////////
// Informe de Fcturas
const listaSolicitudes = (params) => {
  return fetch(rutaApi + '/listaSolicitudes/' + params.proveedor, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



export {
  listaSolicitudes
}